import React from 'react';
import Layout from '../components/layout';
import Main from '../components/main';
import SEO from '../components/seo';
import LatestPostTemplate from './wp-latestposts';

export default (props) => {
  const { pageContext } = props;
  const { title, content, canonical, description, featuredImage, readingSettings, breadcrumbs } = pageContext;

  return readingSettings ? (
    <LatestPostTemplate {...props} />
  ) : (
    <Layout>
      <SEO description={description} title={title} link={[{ rel: 'canonical', href: canonical }]} />
      <Main
        breadcrumbs={breadcrumbs.length > 1 ? breadcrumbs : undefined}
        featuredImage={featuredImage}
        headingContent={pageContext.page_heading_area?.content}
        content={content}
        title={title}
      ></Main>
    </Layout>
  );
};
